@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Roboto|Roboto+Condensed|Roboto+Mono|Roboto+Slab&display=swap");
@import url("./../CPQ/FFClan.css");
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.update-title{
	font-size: 16px;
	font-weight: bold;
}
.update-description{
	margin-top: 2%;
	font-size: 14px;
}
.update-date{
	margin-top: 2%;
	font-size: 12px;
}

tr.hide-table-padding td {
	padding: 0;
}

.expand-button {
	position: relative;
}

.accordion-toggle .expand-button:after {
	position: absolute;
	left: 0.75rem;
	top: 50%;
	transform: translate(0, -50%);
	/* content: "-"; */
	/* content: "\f078";
    font-family: "Font Awesome 5 Free"; */
    color:#000;
    content: '\f078'; /* You should use \ and not /*/
    font-family: "Font Awesome 5 Free"; /* This is the correct font-family*/
    font-style: normal;
    font-weight: bold;
    font-size: 1 rem;
}

.accordion-toggle.collapsed .expand-button:after {
    color:#000;
    content: '\f054'; /* You should use \ and not /*/
    font-family: "Font Awesome 5 Free"; /* This is the correct font-family*/
    font-style: normal;
    font-weight: bold;
    font-size: 1 rem;
}

.pi-times:before {
	content: "\E902" !important;
}

.ant-descriptions-item-label .ant-descriptions-item-content {
	font-size: 15px !important;
}

.ant-table .ant-table-tbody > tr:hover > td {
	background: unset !important;
}

.ant-table-small > .ant-table-content > .ant-table-body {
	margin: 0 0 !important;
}

.ant-comment-content-author-name {
	font-size: 15px !important;
	color: black !important;
	/* font-weight: 900 !important; */
}

.ant-comment-content-author-time {
	padding-top: 2px !important;
	color: rgba(0, 0, 0, 0.5) !important;
}

.ant-comment-content-detail {
	font-size: 14px !important;
	color: rgba(0, 0, 0, 1) !important;
}

.ant-descriptions-row {
	border: 1px solid #f0f0f0 !important;
}
.ant-modal {
	width: 90% !important;
}

.card {
	box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.3) !important;
}

.currency-border {
	border: 1px solid #eceff1;
}

.table td,
.table th {
	vertical-align: middle !important;
	font-size: 14px;
	/* letter-spacing: 0.3px; */
	font-weight: 500 !important;
	border-top: none !important;
}

#container {
	position: relative;
	color: #000000 !important;
}

#nested {
	position: absolute;
	top: -8px;
	left: -8px;
	font-size: 225%;
	color: rgba(217, 83, 79, 0.7);
}
.p-steps-title {
	/* font-weight: 500 !important; */
}

.ant-select-arrow {
	color: #000000 !important;
}

.btn {
	border-radius: 0 !important;
}

/* .file-input__input {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.file-input__label {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	color: #fff;
	font-size: 14px;
	padding: 10px 12px;
	border-radius: 0 !important;
	background-color: #4245a8;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
	height: 16px;
	margin-right: 4px;
} */
.multi-select {
	border-radius: 4px !important;
}

.p-multiselect {
	width: 100% !important;
	min-width: auto !important;
	min-height: auto !important;
	border-radius: 4px !important;
}

.p-tree {
	margin: 5% !important;
}
.tdl-holder {
	margin: 0 auto;
}
.tdl-holder ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.tdl-holder li {
	position: relative;
	background-color: transparent;
	list-style: outside none none;
	margin: 0;
	padding: 7px 0;
}
.tdl-holder li:after,
.tdl-holder li:before {
	content: "\f142";
	top: 24px;
	position: absolute;
	font: normal normal normal 14px/1 FontAwesome;
	-moz-osx-font-smoothing: grayscale;
	z-index: 99;
	color: #99abb4;
}
.tdl-holder li:after {
	left: 10px;
}
.tdl-holder li:before {
	left: 14px;
}
.tdl-holder li span {
	margin-left: 30px;
	vertical-align: middle;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.tdl-holder label {
	cursor: pointer;
	display: block;
	line-height: 50px;
	padding: 0 15px;
	padding-left: 30px;
	position: relative;
	margin: 0 !important;
	background: rgba(0, 0, 0, 0.05);
	color: #99abb4;
	font-size: 15px;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.tdl-holder label:hover {
	background-color: rgba(153, 171, 180, 0.1);
}
.tdl-holder label a {
	border-radius: 50%;
	color: rgba(153, 171, 180, 0.5);
	float: right;
	line-height: normal;
	margin-top: 15px;
	text-align: center;
	text-decoration: none;
	height: 16px;
	width: 20px;
	-webkit-transition: all 0.2s linear;
	transition: all 0.2s linear;
}
.tdl-holder label a:hover {
	color: rgba(153, 171, 180, 0.8);
}
.tdl-holder input[type="checkbox"] {
	cursor: pointer;
	opacity: 0;
	position: absolute;
}
.tdl-holder input[type="checkbox"] + i {
	border: 1px solid rgba(153, 171, 180, 0.3);
	display: block;
	height: 18px;
	position: absolute;
	top: 15px;
	width: 18px;
	z-index: 1;
}
.tdl-holder input[type="checkbox"]:checked + i::after {
	content: "\e64c";
	font-family: "themify";
	display: block;
	color: rgba(153, 171, 180, 0.5);
	left: 0;
	position: absolute;
	top: -16px;
	z-index: 2;
}
.tdl-holder input[type="checkbox"]:checked ~ span {
	text-decoration: line-through;
}
.tdl-holder input[type="text"] {
	height: 60px;
	margin-top: 20px;
	font-size: 14px;
}
.flat-color-1 {
	color: #00c292;
}

.flat-color-2 {
	color: #ab8ce4;
}

.flat-color-3 {
	color: #03a9f3;
}

.flat-color-4 {
	color: #fb9678;
}

.flat-color-5 {
	color: #66bb6a;
}

.flat-color-6 {
	color: #5c6bc0;
}
@keyframes swing {
	0% {
		transform: rotate(0deg);
	}
	10% {
		transform: rotate(10deg);
	}
	30% {
		transform: rotate(0deg);
	}
	40% {
		transform: rotate(-10deg);
	}
	50% {
		transform: rotate(0deg);
	}
	60% {
		transform: rotate(5deg);
	}
	70% {
		transform: rotate(0deg);
	}
	80% {
		transform: rotate(-5deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes sonar {
	0% {
		transform: scale(0.9);
		opacity: 1;
	}
	100% {
		transform: scale(2);
		opacity: 0;
	}
}
body {
	font-size: 0.9rem;
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
	height: 100vh;
}

.page-wrapper .theme {
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 4px;
	margin: 2px;
}

.page-wrapper .theme.chiller-theme {
	background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
	left: 0px;
}

@media screen and (min-width: 768px) {
	.page-wrapper.toggled .page-content {
		padding-left: 260px;
	}
}
/*----------------show sidebar button----------------*/
#show-sidebar {
	position: fixed;
	left: 0;
	top: 10px;
	border-radius: 0 4px 4px 0px;
	width: 35px;
	transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
	left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
	width: 285px;
	height: 100%;
	max-height: 100%;
	position: fixed;
	top: 0;
	left: -300px;
	z-index: 999;
}

.sidebar-wrapper ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.sidebar-wrapper a {
	text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
	max-height: calc(100% - 30px);
	height: calc(100% - 30px);
	overflow-y: auto;
	position: relative;
}

.sidebar-content.desktop {
	overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
	padding: 10px 20px;
	display: flex;
	align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
	text-transform: uppercase;
	font-weight: bold;
	flex-grow: 1;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
	cursor: pointer;
	font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
	padding: 20px;
	overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
	float: left;
	width: 60px;
	padding: 2px;
	border-radius: 12px;
	margin-right: 15px;
	overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
	float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
	display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
	font-size: 12px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status {
	font-size: 11px;
	margin-top: 4px;
}

.sidebar-wrapper .sidebar-header .user-info .user-status i {
	font-size: 8px;
	margin-right: 4px;
	color: #5cb85c;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
	padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
	padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
	font-weight: bold;
	font-size: 14px;
	padding: 15px 20px 5px 20px;
	display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
	display: inline-block;
	width: 100%;
	text-decoration: none;
	position: relative;
	padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
	margin-right: 10px;
	font-size: 12px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
	display: inline-block;
	animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f105";
	font-style: normal;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	background: 0 0;
	position: absolute;
	right: 15px;
	top: 14px;
}
.sidebar-wrapper .sidebar-menu .sidebar-dropdown {
	font-size: 13px !important;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
	padding: 5px 0;
	list-style-type: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
	padding-left: 20px;
	font-size: 12px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
	content: "\f111";
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
	font-style: normal;
	display: inline-block;
	text-align: center;
	text-decoration: none;
	-moz-osx-font-smoothing: grayscale;
	margin-right: 5px;
	font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
	float: right;
	margin-top: 8px;
	margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
	float: right;
	margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
	display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
	transform: rotate(90deg);
	right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	display: flex;
}

.sidebar-footer > a {
	flex-grow: 1;
	text-align: center;
	height: 30px;
	line-height: 30px;
	position: relative;
}

.sidebar-footer > a .notification {
	position: absolute;
	top: 0;
}

.badge-sonar {
	display: inline-block;
	background: #980303;
	border-radius: 50%;
	height: 8px;
	width: 8px;
	position: absolute;
	top: 0;
}

.badge-sonar:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	border: 2px solid #980303;
	opacity: 0;
	border-radius: 50%;
	width: 100%;
	height: 100%;
	animation: sonar 1.5s infinite;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
	display: inline-block;
	width: 100%;
	padding-left: 0px;
	padding-top: 20px;
}

.page-wrapper .page-content > div {
	padding: 20px 40px;
}

.page-wrapper .page-content {
	overflow-x: hidden;
}

/*------scroll bar---------------------*/

/* ::-webkit-scrollbar {
	width: 15px;
	height: 10px;
}
::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}
::-webkit-scrollbar-thumb {
	background: #525965;
	border: 0px none #ffffff;
	border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
	background: #525965;
}
::-webkit-scrollbar-thumb:active {
	background: #525965;
}
::-webkit-scrollbar-track {
	background: transparent;
	border: 0px none #ffffff;
	border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
	background: transparent;
}
::-webkit-scrollbar-track:active {
	background: transparent;
}
::-webkit-scrollbar-corner {
	background: transparent;
} */

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
	background: #31353d;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
	border-top: 1px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
	border-color: transparent;
	box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
	color: #818896;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover > a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand > a:hover,
.chiller-theme .sidebar-footer > a:hover i {
	color: #b8bfce;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
	color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
	color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme
	.sidebar-wrapper
	.sidebar-dropdown
	.sidebar-submenu
	li
	a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus + span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
	color: #16c7ff;
	text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
	background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
	color: #6c7b88;
}

.chiller-theme .sidebar-footer {
	background: #3a3f48;
	box-shadow: 0px -1px 5px #282c33;
	border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer > a:first-child {
	border-left: none;
}

.chiller-theme .sidebar-footer > a:last-child {
	border-right: none;
}

* {
	font-family: "FF Clan";
}

td {
	color: black;
}

.p-growl-message {
	font-family: "FF Clan";
}

.swal2-title {
	font-family: "FF Clan";
}

.swal2-content {
	font-family: "FF Clan";
}

button.swal2-styled.swal2-confirm {
	border-radius: 0;
}

button.swal2-styled.swal2-cancel {
	border-radius: 0;
}

.swal2-styled {
	font-family: "FF Clan";
}

body
	.p-multiselect-panel
	.p-multiselect-header
	.p-multiselect-filter-container
	.p-multiselect-filter-icon {
	color: rgba(185, 55, 61, 1) !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
	border-color: rgba(185, 55, 61, 1) !important;
	background-color: rgba(185, 55, 61, 1) !important;
	color: #ffffff;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
	background-color: rgba(185, 55, 61, 1) !important;
	color: #ffffff;
}

body {
	font-family: "FF Clan";
}

label {
	font-family: "FF Clan";
}

.p-steps-title {
	font-family: "FF Clan" !important;
}

.p-disabled {
	opacity: 1 !important;
}

.p-steps-number {
	padding-top: 1px !important;
	top: 18px !important;
	font-family: "FF Clan";
}

.red-link {
	color: rgba(185, 55, 61, 1) !important;
}

.p-datepicker table {
	width: auto !important;
}

.p-calendar {
	margin-left: -13%;
}

.btn-group-xs > .btn,
.btn-xs {
	padding: 0.25rem 0.4rem;
	font-size: 0.875rem;
	line-height: 0.5;
	border-radius: 0.2rem;
}

.five-percent {
	width: 5% !important;
}

.ten-percent {
	width: 10% !important;
}

.eleven-percent {
	width: 11% !important;
}

.twenty-percent {
	width: 20% !important;
}

.thirty-percent {
	width: 30% !important;
}

header {
	height: 70px;
}

.admin-logo {
	height: 40px;
}
.logo {
	height: 50px;
}

table {
	table-layout: fixed;
}

th,
td {
	word-wrap: break-word;
}

.wrap {
	word-wrap: break-word;
}

.no-resize {
	resize: none;
}

.nav-link {
	font-family: "FF Clan";
	font-size: 14px;
	/* font-weight: 500; */
	color: black;
	/* letter-spacing: 0.2px; */
	/* letter-spacing: 0.2px; */
}

.navbar-nav {
	float: left;
}

.dropdown-menu {
	border-radius: 0;
	/* background-color: #e0e0e0;  */
}
.dropdown-item {
	font-family: "FF Clan";
	font-size: 15px;
}

.icon {
	margin: 5%;
}

.swal2-textarea {
	height: 100px;
	font-size: 15px;
}

.swal2-icon.swal2-info {
	border-color: #f27474 !important;
	color: #f27474 !important;
}

footer > .container-fluid {
	background-color: #fff;
	text-decoration-color: #000;
}

.client-white-text {
	color: rgba(255, 255, 255, 0.9);
}
a.client-white-text:hover {
	color: rgba(255, 255, 255, 0.4);
}

.client-roboto-condensed {
	font-family: "FF Clan";
}

.client-copoyright-link {
	color: black;
}

.client-list-unstyled {
	color: #fff;
}

.client-login:disabled {
	border: 0;
	border-radius: 4px !important;
	background-color: rgba(185, 55, 61, 0.5);
	color: white;
	padding: 5px 5px;
	font-size: 14px;
	/* letter-spacing: 0.3px; */
}

.client-login {
	border: 0;
	border-radius: 4px !important;
	background-color: rgba(185, 55, 61, 1);
	color: white;
	padding: 5px 5px;
	font-size: 14px;
	/* letter-spacing: 0.3px; */
}

.client-login-reverse {
	background-color: white;
	color: rgba(185, 55, 61, 1);
	border: 2px solid rgba(185, 55, 61, 1);
	padding: 5px 5px;
	font-size: 15px;
	border-radius: 4px !important;
}

.client-login2 {
	border: 0;
	background-color: rgba(185, 55, 61, 1);
	color: white;
}

input::placeholder {
	font-family: "FF Clan";
}

input[type="checkbox"] {
	background-color: #e1e1e1;
	height: 15px;
	width: 15px;
}

.client-custom-border {
	width: 50px;
	border-radius: 0 !important;
	background-color: inherit;
	height: 30px;
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 2px solid rgb(185, 55, 61);
}

input[type="number"] {
	width: 45px;
	text-align: center;
	margin-top: 0%;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 4px;
	height: 30px;
	border: 2px solid rgb(185, 55, 61);
}

input[type="number"] {
	-moz-appearance: textfield !important;
}

input {
	font-family: "FF Clan";
	/* font-weight: 500; */
	font-size: 14px !important;
	border-radius: 4px !important;
}

.form-control {
	border-radius: 0;
}

.ui-steps.steps-custom {
	margin-bottom: 30px;
}

.ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
	height: 10px;
	padding: 0 1em;
	overflow: visible;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-number {
	background-color: #0081c2;
	color: #ffffff;
	display: inline-block;
	width: 36px;
	border-radius: 50%;
	margin-top: -14px;
	margin-bottom: 10px;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-title {
	color: #555555;
}

.p-steps .p-steps-item {
	width: 33.33% !important;
}

.infinite-scroll-component {
	height: 250px !important;
}
.client-config-details {
	width: auto;
}

.client-config {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}
.client-config > span {
	margin-top: 5px;
	/* padding: 10px;
    font-family: 'FF Clan'; */
}

.client-selected-parts {
	width: 110%;
}

.client-disclaimer {
	font-size: 14px;
	color: red;
}

.client-success-page {
	margin: 0;
}

.client-cards-list {
	z-index: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-content: space-between;
	flex-wrap: wrap;
}

.client-card {
	margin: 20px 10px;
	width: 200px;
	height: 200px;
	border-radius: 40px;
	box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0client-25),
		-5px -5px 30px 7px rgba(0, 0, 0, 0client-22);
	cursor: pointer;
	transition: 0client-4s;
}

.client-card .client-card_image {
	width: inherit;
	height: inherit;
	border-radius: 40px;
}

.client-card .client-card_image img {
	width: inherit;
	border: 1px solid;
	padding: 10px;
	box-shadow: 5px 10px #999999;
	height: inherit;
	border-radius: 40px;
	object-fit: cover;
	padding: 50px !important;
}

.client-card .client-card_title {
	text-align: center;
	border-radius: 0 0 40px 40px;
	font-family: sans-serif;
	font-weight: bold;
	font-size: 15px;
	margin-top: -50px;
	height: 40px;
}

.client-card_title > a {
	color: black;
}

.client-card_title > a:hover {
	text-decoration: none;
	color: black;
	font-style: italic;
}

.client-card:hover {
	transform: scale(0client-9, 0client-9);
	box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0client-25),
		-5px -5px 30px 15px rgba(0, 0, 0, 0client-22);
}

.client-title-white {
	color: white;
	text-shadow: 1px 1px #ffffff;
}

.client-title-black {
	color: black;
	text-shadow: 1px 1px #ffffff;
}
@media all and (max-width: 500px) {
	.client-card-list {
		/* On small screens, we are no longer using row direction but column */
		flex-direction: column;
	}
}

.client-detail-image {
	width: 200px;
	height: 200px;
	border-radius: 40px;
}

.client-select-menu {
	width: 100%;
	height: 100%;
	padding: 3%;
}

ul {
	padding-inline-start: 0;
}

.client-min-width-table {
	min-width: 50px;
}

.half {
	width: 50%;
}

.full {
	width: 100%;
}

.center-input {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.dropdown-content {
	width: auto;
}

.no-text-decoration > a > p {
	color: black;
}

a.hover:hover {
	text-decoration: #111111;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
	min-width: 100%;
	position: relative;
}

.text-left {
	text-align: left;
}

.multi-select {
	min-width: 20rem;
	max-width: 100%;
}

.modal-content {
	width: 70rem;
	margin-left: -60%;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
	background: rgba(185, 55, 61, 1) !important;
	color: #ffffff;
}
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
	background: rgba(185, 55, 61, 1) !important;
	color: #ffffff;
}

button.tag-remove {
	display: none;
}

span.tag {
	padding-right: 0.5em;
}

a.client-login:hover {
	text-decoration: none;
	color: white;
	background-color: rgba(155, 55, 61, 1);
}

a.client-login-reverse:hover {
	text-decoration: none;
	color: rgba(155, 55, 61, 1);
	background-color: whitesmoke;
}

tr {
	/* border: solid 2px rgba(185, 55, 61, 1) !important; */
	/* border-color: rgba(185, 55, 61, 1) !important; */
}

/* thead > tr {
	line-height: 10px;
}

tbody > tr {
	line-height: 8px;
} */
.counter {
	margin-top: -9px;
	margin-bottom: -9px;
}
.client-counter {
	margin-top: -9px;
	margin-bottom: -9px;
	width: 125px;
}

table > thead {
	background-color: rgba(185, 55, 61, 1);
	color: #ffffff;
}

/* th {
	font-weight: 900 !important;
} */
/*
td {
	line-height: 100%;
} */
#banner {
	box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.7);
}

.header-seperator {
	background-color: rgba(185, 55, 61, 1);
	height: 20px;
}

.footer-link {
	color: rgba(0, 0, 0, 0.65) !important;
}

.footer-link:hover {
	color: #0081c2 !important;
}

hr {
	height: 0px;
	background-color: rgba(85, 85, 85, 0.5);
}
input:focus,
textarea {
	outline: none !important;
}

.money {
	width: 7rem;
}

/* tr > td {
	padding-top: 1.2rem !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
	-moz-appearance: textfield; /* Firefox */
}

.plus-minus {
	background-color: #ffffff;
	border: 2px solid rgb(185, 55, 61);
	border-radius: 4px;
	/* height: 1.5rem; */
	height: 30px;
	width: 1.5rem;
	color: #000000;
	font-size: 16px;
	text-align: center;
	margin: 0% 5% 0 5%;
}

button:focus {
	outline: none !important;
}

.loading-container {
	margin-top: 10% !important;
	margin-bottom: 10% !important;
}

.card {
	border-radius: 0px;
	/* height: 100%; */
	width: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	/* margin-top:10%; */
	background-size: auto 100px;
	background-position: center 25%;
	background-repeat: no-repeat;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
	overflow: hidden;
}

.card-blur {
	position: absolute;
	height: 100%;
	width: calc(100% + 1px);
	background-color: black;
	opacity: 0;
	transition: opacity 0.15s ease-in;
}
.card-blur-green {
	position: absolute;
	height: 100%;
	width: calc(100% + 1px);
	background-color: rgba(46, 204, 113, 0.7);
	opacity: 0;
	transition: opacity 0.15s ease-in;
}
.card-blur-red {
	position: absolute;
	height: 100%;
	width: calc(100% + 1px);
	background-color: rgba(185, 55, 61, 0.7);
	opacity: 0;
	transition: opacity 0.15s ease-in;
}

.card:hover .card-blur {
	opacity: 0.6;
}

.card:hover .card-blur-green {
	opacity: 0.6;
}

.card:hover .card-blur-red {
	opacity: 0.6;
}

.footer {
	z-index: 1;
	position: absolute;
	height: 70px;
	width: 100%;
	bottom: 0;
}

svg[id*="curve-"] {
	position: absolute;
	fill: white;
	left: 0;
	bottom: 0;
	width: 200px;
	height: 200px;
}

.connections {
	height: 70px;
	width: 400px;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100px;
	margin: auto;
}

.connection {
	height: 25px;
	width: 25px;
	border-radius: 100%;
	background-color: white;
	display: inline-block;
	padding: 5px;
	margin-right: 25px;
	transform: translateY(200px);

	transition: transform 1s cubic-bezier(0.46, 1.48, 0.18, 0.81);
}

.card:hover .connection {
	transform: translateY(0px);
}

.connection.facebook {
	margin-left: 20px;
	padding: 5px;
}

.connection.twitter {
	transition-delay: 0.02s;
}
.image-card {
	display: inline-block;
	background: white;
	border-radius: 20px;
	box-shadow: 0px 2px 10px -4px black;
}

.imagewrap {
	display: inline-block;
	position: relative;
	padding: 20px;
}

.thumbnail {
	width: auto;
	height: auto;
	overflow: hidden;
}

.thumbnail img {
	opacity: 1;
	width: 200px;
	height: auto;
	transition: all 500ms;
}

.imagewrap:hover img {
	opacity: 0.5;
}

.button1 {
	position: absolute;
	top: 5%;
	left: 85%;
}

.ig-btn {
	opacity: 0;
	background-color: red;
	right: 35%;
	border: none;
	border-radius: 20px;
	min-height: 30px;
	width: 30px;
	color: white;
	transition: all 500ms;
	text-transform: uppercase;
	font-size: 9px;
}

.ig-btn:hover {
	background-color: orangered;
}

.imagewrap:hover .ig-btn {
	opacity: 1;
}

/* .info {
	color: #aaa;
	padding: 0px 20px 0px 20px;
} */

.info {
	/* font-family: Inconsolata; */
	/* font-weight: bold; */
	/* padding-left: 20px; */
	padding-top: 1%;
	padding-left: 1%;
	padding-right: 1%;
	text-align: center;
	/* transform: translateY(250px); */

	/* transition: transform 1s cubic-bezier(.31,1.21,.64,1.02); */
}

.card:hover .info {
	transform: translateY(0px);
}

.name {
	color: black !important;
	font-weight: bolder;
	padding-top: 10px;
	font-size: 18px;
	/* text-shadow: 5px 5px #ffffff; */
}

.job {
	color: black !important;
	/* font-weight: 900; */
	padding-top: 10px;
	font-size: 15px;
	/* margin-top: 5px; */
}

.custom-select {
	/* border: rgb(73, 80, 87) solid 1px; */
	border-radius: 4px;
}

.p-multiselect {
	border: rgb(73, 80, 87) solid 1px;
	border-radius: 4px !important;
}

.p-inputtext {
	border: rgb(73, 80, 87) solid 1px;
	border-radius: 0px !important;
}

.p-checkbox .p-checkbox-box {
	border: rgb(73, 80, 87) solid 1px;
	border-radius: 0px !important;
	margin-right: 1.5em !important;
}

.p-tree {
	border-radius: 1px !important;
}

input[type="radio"] {
	border: 1px solid #000;
	padding: 0.5em;
	-webkit-appearance: none;
}

input[type="radio"]:checked {
	background-color: black;
	background-size: 8px 8px;
}

/* input[type=checkbox]{
    border: rgb(73, 80, 87) solid 1px !important;
    border-radius: 0px !important;
} */

.library-checkbox {
	margin-right: 0.5em;
	margin-bottom: 1em;
}

.discount-input {
	text-align: right;
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 0px;
	border: rgba(185, 55, 61, 1) solid 2px;
}

input[type="checkbox"] {
	position: relative;
	cursor: pointer;
}
input[type="checkbox"]:before {
	content: "";
	display: block;
	position: absolute;
	width: 25px;
	height: 25px;
	top: 0;
	left: 0;
	border: 2px solid rgba(185, 55, 61, 1);
	border-radius: 3px;
	background-color: white;
}
input[type="checkbox"]:checked:after {
	content: "";
	display: block;
	width: 9px;
	height: 18px;
	border: solid #000000;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	position: absolute;
	top: 2px;
	left: 8px;
}

/**
 * Oscuro: #283035
 * Azul: #03658c
 * Detalle: #c7cacb
 * Fondo: #dee1e3
 ----------------------------------*/
* {
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color: #03658c;
	text-decoration: none;
}

ul {
	list-style-type: none;
}

/** ====================
* Lista de Comentarios
=======================*/
.comments-container {
	/* margin: 60px auto 15px; */
	width: 768px;
}

.comments-container h1 {
	font-size: 36px;
	color: #283035;
	font-weight: 400;
}

.comments-container h1 a {
	font-size: 18px;
	font-weight: 700;
}

.comments-list {
	/* margin-top: 30px; */
	position: relative;
}

/**
* Lineas / Detalles
-----------------------*/
/* .comments-list:before {
   content: '';
   width: 2px;
   height: 100%;
   background: #c7cacb;
   position: absolute;
   left: 32px;
   top: 0;
} */

/* .comments-list:after {
   content: '';
   position: absolute;
   background: #c7cacb;
   bottom: 0;
   left: 29px;
   width: 7px;
   height: 7px;
   border: 3px solid #dee1e3;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   border-radius: 50%;
} */

.reply-list:before,
.reply-list:after {
	display: none;
}
.reply-list li:before {
	content: "";
	width: 60px;
	height: 2px;
	background: #c7cacb;
	position: absolute;
	top: 25px;
	left: -55px;
}

.comments-list li {
	margin-bottom: 15px;
	display: block;
	position: relative;
}

.comments-list li:after {
	content: "";
	display: block;
	clear: both;
	height: 0;
	width: 0;
}

.reply-list {
	padding-left: 88px;
	clear: both;
	margin-top: 15px;
}
/**
* Avatar
---------------------------*/
.comments-list .comment-avatar {
	width: 65px;
	height: 65px;
	position: relative;
	z-index: 99;
	float: left;
	border: 3px solid #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}

.comments-list .comment-avatar img {
	width: 100%;
	height: 100%;
}

.reply-list .comment-avatar {
	width: 50px;
	height: 50px;
}

.comment-main-level:after {
	content: "";
	width: 0;
	height: 0;
	display: block;
	clear: both;
}
/**
* Caja del Comentario
---------------------------*/
.comments-list .comment-box {
	width: 680px;
	float: right;
	position: relative;
	-webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.comments-list .comment-box:before,
.comments-list .comment-box:after {
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	display: block;
	border-width: 10px 12px 10px 0;
	border-style: solid;
	border-color: transparent #fcfcfc;
	top: 8px;
	left: -11px;
}

.comments-list .comment-box:before {
	border-width: 11px 13px 11px 0;
	border-color: transparent rgba(0, 0, 0, 0.05);
	left: -12px;
}

.reply-list .comment-box {
	width: 610px;
}
.comment-box .comment-head {
	background: #fcfcfc;
	padding: 10px 12px;
	border-bottom: 1px solid #e5e5e5;
	overflow: hidden;
	-webkit-border-radius: 4px 4px 0 0;
	-moz-border-radius: 4px 4px 0 0;
	border-radius: 4px 4px 0 0;
}

.comment-box .comment-head i {
	float: right;
	margin-left: 14px;
	position: relative;
	top: 2px;
	color: #a6a6a6;
	cursor: pointer;
	-webkit-transition: color 0.3s ease;
	-o-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.comment-box .comment-head i:hover {
	color: #03658c;
}

.comment-box .comment-name {
	color: #283035;
	font-size: 14px;
	float: left;
	margin-right: 10px;
}

.comment-box .comment-name a {
	color: #283035;
}

.comment-box .comment-head span {
	float: left;
	color: #999;
	font-size: 13px;
	position: relative;
	top: 1px;
}

.comment-box .comment-content {
	background: #fff;
	padding: 12px;
	font-size: 15px;
	color: #595959;
	border-radius: 0 0 4px 4px;
}

.comment-box .comment-name.by-author,
.comment-box .comment-name.by-author a {
	color: rgba(185, 55, 61, 1);
	font-family: "FF Clan";
}
.comment-box .comment-name.by-author:after {
	content: "Author";
	background: rgba(185, 55, 61, 1);
	color: #fff;
	font-size: 12px;
	padding: 3px 5px;
	margin-left: 10px;
	border-radius: 3px;
}

/** =====================
* Responsive
========================*/
@media only screen and (max-width: 766px) {
	.comments-container {
		width: 480px;
	}

	.comments-list .comment-box {
		width: 390px;
	}

	.reply-list .comment-box {
		width: 320px;
	}
}

/* Comment Box Styles */

@import url("https://fonts.googleapis.com/css?family=Lato:300, 400");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css");
*,
*:before,
*:after {
	margin: 0;
	padding: 0;
	/* -webkit-box-sizing: border-box; */
	transition: all 0.2s ease;
}

.user_avatar {
	width: 65px;
	height: 65px;
	display: inline-block;
	vertical-align: middle;
}
.user_avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.comment_block {
	width: 65%;
	height: 100%;
	padding: 10px;
}
.comment_block .create_new_comment {
	width: 100%;
	padding: 20px 0;
}
.comment_block .create_new_comment .input_comment {
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px;
	width: calc(100% - 75px);
}
.comment_block .create_new_comment .input_comment input[type="text"] {
	width: 100%;
	font-family: "FF Clan", sans-serif;
	font-weight: 300;
	font-size: 1.3rem;
	padding: 10px;
	border: none;
	border-bottom: 2px solid #f2f2f2;
}
.comment_block .create_new_comment .input_comment input[type="text"]:focus {
	outline: none;
	border-bottom: 2px solid #e6e6e6;
}
.comment_block .new_comment {
	width: 100%;
	height: auto;
	padding: 20px 0;
	border-top: 1px solid #e6e6e6;
}
.comment_block .new_comment .user_comment {
	list-style-type: none;
}
.comment_block .new_comment .comment_body {
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 75px);
	min-height: 65px;
	margin-left: 10px;
	padding: 5px 10px;
	font-size: 0.9rem;
	color: #555;
	background-color: #fff;
	border-bottom: 2px solid #f2f2f2;
}
.comment_block .new_comment .comment_body .replied_to {
	margin: 5px 0px;
	background-color: #fafafa;
	border-bottom: 2px solid #f2f2f2;
	border-radius: 5px;
}
.comment_block .new_comment .comment_body .replied_to p {
	padding: 5px;
}
.comment_block .new_comment .comment_body .replied_to span {
	color: #6495ed;
	margin-right: 2px;
}
.comment_block .new_comment .comment_toolbar {
	width: 100%;
}
.comment_block .new_comment .comment_toolbar ul {
	list-style-type: none;
	padding-left: 75px;
	font-size: 0;
}
.comment_block .new_comment .comment_toolbar ul li {
	display: inline-block;
	padding: 5px;
	font-size: 0.7rem;
	color: #d9d9d9;
}
.comment_block .new_comment .comment_toolbar ul li:hover {
	cursor: pointer;
}
.comment_block .new_comment .comment_toolbar .comment_details {
	display: inline-block;
	vertical-align: middle;
	width: 70%;
	text-align: left;
}
.comment_block .new_comment .comment_toolbar .comment_tools {
	display: inline-block;
	vertical-align: middle;
	width: 30%;
	text-align: right;
}
.comment_block .new_comment .comment_toolbar .comment_tools li:hover {
	color: #ccc;
}
.comment_block .new_comment .user:hover {
	color: #6495ed;
	text-decoration: underline;
}
.comment_block .new_comment .love:hover {
	color: #ff6347;
}

.card .card-body {
	float: left;
	padding: 1.25em;
	position: relative;
	width: 100%;
}
.stat-widget-five {
	min-height: 60px;
}
.stat-widget-five .stat-icon {
	font-size: 50px;
	line-height: 50px;
	position: absolute;
	left: 30px;
	top: 20px;
}
.stat-widget-five .stat-content {
	margin-left: 100px;
}
.stat-widget-five .stat-text {
	color: #455a64;
	font-size: 20px;
}
.stat-widget-five .stat-heading {
	color: #99abb4;
	font-size: 14px;
}