@import url("https://fonts.googleapis.com/css?family=Sue+Ellen+Francisco&display=swap");

#error {
  font-family: "Sue Ellen Francisco", cursive !important;
  font-size: 3em;
  text-align: center;
  opacity: 0.8;
  order: 1;
}

#error-message {
  font-family: "Sue Ellen Francisco", cursive !important;
  /* font-size: 3em; */
  text-align: center;
  opacity: 0.8;
  order: 1;
}

small {
  font-family: "Sue Ellen Francisco", cursive !important;
  /* font-size: 3em; */
  text-align: center;
  opacity: 0.8;
  order: 1;
}

#error small {
  display: block;
}

.site {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 4.2%;
  margin-bottom: 4.2%;
  /* background-color:; */
}

.sketch {
  position: relative;
  height: 100%;
  min-width: 400px;
  margin: 0;
  overflow: visible;
  order: 2;
}

.bee-sketch {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (min-width: 780px) {
  .site {
    flex-direction: row;
    padding: 1em 3em 1em 0em;
  }

  h1 {
    text-align: right;
    order: 2;
    padding-bottom: 2em;
    padding-left: 2em;
  }

  .sketch {
    order: 1;
  }
}
